<template>
    <header>
        <BannerHomepage/>
    </header>
    <main>
        <About/>
        <!-- <AtencionOnline/> -->
        <FeaturedBlogs/>
    </main>
    <footer>
      <Footer/>
    </footer>
</template>

<script>
import About from '@/components/About/About.vue';
import FeaturedBlogs from '@/components/FeaturedBlogs/FeaturedBlogs.vue';
import BannerHomepage from '@/components/BannerHomepage/BannerHomepage.vue';
import Footer from '@/components/Footer/Footer.vue';
import AtencionOnline from '@/components/AtencionOnline/AtencionOnline.vue';

export default {
  components: {
        About,
        FeaturedBlogs,
        BannerHomepage,
        Footer,
        AtencionOnline,
    }
}
</script>

<style scoped>

.footerContainer{
    background-color: #FFF;
}

</style>