<template>
    <header>
        <section class="banner" id="bannerA-Sol-As">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">A-SOL-AS</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 25/04/2020</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>A-SOL-AS</h2>
                <div class="citaContainer">
                    <p class="citaBlog">
                        “Se generan nuevos órganos de percepción
                        como resultado de la necesidad. <br>
                        Por lo tanto, hombre, <br>
                        incrementa tu necesidad <br>
                        para aumentar tu percepción” <br> <br>
                        Jallaludin Rumi <br>
                        1207-1273 <br> <br>
                    </p>
                </div>

                <p style="margin-bottom: 10px;">
                    En estos días de cuarentena y pandemia escuchamos,
                    leemos acerca de la importancia de estar al sol para que  se
                    active la vitamina D y con ello fortalezcamos el sistema inmune.
                    La idea de tener que hacer más   cosas para   preservar nuestra salud además   de estar aislados,   puede   parecer 
                    <br>
                </p>
                <div class="elementContainer">
                    <div class="imageWithText" style="padding: 0px">
                        <img class="imageSection" src="@/assets/banners/a-sol-as.jpg" alt="susanaAvella">
                        <div class="InfoSection">
                            <p class="paraSection">
                                agobiante pero no colapses. Tomar sol o
                                estar al sol se puede hacer a la par de otra actividad: leer, tomar mate,
                                desayunar, almorzar, merendar, regar las plantas, mientras hacés tu rutina
                                de ejercicios, mientras elongás, etc. También podemos utilizar la toma de
                                sol para contactar con nuestro cuerpo y reconocerlo. Se me fueron ocurriendo
                                las siguientes líneas un Jueves Santo de cuarentena (pandemia COVID 19)
                                mientras desayunaba al sol. Había sido una noche fría y salí al jardín
                                vestida con jeans, remera manga larga, zapatillas y medias. Estaba sobre
                                una reposera 100% al sol de la mañana (9:30 am). Conforme iban pasando los minutos,
                            </p>
                        </div>
                    </div>
                </div>
                <p>
                    el sol se hacía sentir, y me saqué las zapatillas,
                    las medias y luego el abrigo para quedar con más partes del cuerpo expuestas al sol.
                    <br> <br>
                    En mi trabajo con deportistas intento que los ejercicios de preparación psicológica formen parte de la vida cotidiana o que los incluyan mientras practican o entrenan su disciplina.
                    <br> <br>
                    Acá va el ejercicio que podés hacer:
                    <br> <br>
                    – Podés escanear tu cuerpo al Sol. Discriminar las zonas de mayor impacto.
                    Si estás de cara, reconocer dónde te pega más y dónde menos, dónde es placentero
                    y dónde no lo es. Tal vez te dé mucho sol en el rostro a tal punto que tengas que
                    fruncir el ceño o se te achinen los ojos, y eso que fue placentero en un momento,
                    al cabo de un rato puede dejar de serlo.
                    <br><br>

                    – Registrá tu espalda, que no está expuesta al Sol. ¿Es constante la temperatura
                    a lo largo de la misma? ¿Cuáles son las zonas que registran confort y en cuáles 
                    se registra incomodidad? Discerní cada uno de los puntos de apoyo de tu cuerpo, pies,
                    piernas, glúteos, espalda, brazos, cabeza. ¿Cómo es la textura en donde apoyan?
                    Su temperatura, si otorgan comodidad o incomodidad al cuerpo, si lo alojan o no.
                    <br><br>

                    – Habiendo escaneado todo el cuerpo, qué maniobras o cambios podrías hacer para
                    que el registro de placer o bienestar se extienda a la mayor parte del cuerpo
                    (cambiar la orientación respecto del Sol, incorporar anteojos, gorra, visera,
                    cambiar la postura, acomodar tu cuerpo sobre una lona o manta que haga la
                    estancia más acogedora, etc, etc.)
                    <br><br>
                </p>
                <p>
                    De seguro estarás pensando ¿qué tiene que ver esto con el deporte?
                    <br><br>

                    Ensayar con el Sol, diariamente, sentir en tu cuerpo su poder
                    estimulante, vivificante, o su capacidad de ponerte incómodo,
                    hace que te actives para aprovechar su energía o para reducir o
                    amortiguar su impacto, te hace incursionar en herramientas para
                    sacarle provecho al entorno o modificar el escenario. ¿Cuántas veces
                    seguiste adelante en una competencia o entrenamiento y luego te diste
                    cuenta que tomarte unos segundos o minutos para cambiar las cosas
                    hubiera sido más favorable? ¿Cuántas veces pensaste que algo era
                    inmodificable, y no lo era? ¿Cuántas veces algo que significaste
                    como negativo terminó jugando a tu favor? ¿Y al revés? Algo que podía
                    jugar a favor, terminó siendo negativo.
                    <br><br>
                    Este ejercicio puede servirte para:
                    <br><br>
                </p>
                    <ul>
                        <li class="listaBlog">Focalizar tu atención</li>
                        <li class="listaBlog">Registrar tu cuerpo</li>
                        <li class="listaBlog">Registrar estímulos motivantes y estresores</li>
                        <li class="listaBlog">Registrar zonas placenteras del cuerpo</li>
                        <li class="listaBlog">Registrar cuál es la temperatura ideal para tu funcionamiento</li>
                        <li class="listaBlog">Registrar tu comodidad e incomodidad</li>
                        <li class="listaBlog">Registrar tu actitud frente al placer/displacer</li>
                    </ul>
                <p>
                    <br>
                    Si bien el estímulo sol es un incontrolable, siempre hay
                    un margen (pequeño o grande) para acomodar el escenario en beneficio propio.
                </p>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';


export default {
  
  components: {
    Footer,
  }
}
</script>

<style>

</style>