<template>
    <div class="footerContainer">
        <div class="elementContainer footer">
            <router-link to="/"><img id="logoFooter" src="@/assets/logoNav.png" alt="logoNav"></router-link>
            <p>Copyright © 2018 - 2024 PsicoDeporteando</p>
            <div class="socialIconsContainer">
                <a target="_blank" rel="noopener" href="https://wa.me/541159584020"><button class="socialIcon"><i class="fa-brands fa-whatsapp fa-xl"></i></button></a>
                <a target="_blank" rel="noopener" href="https://www.instagram.com/susanavella/"><button class="socialIcon"><i class="fa-brands fa-instagram fa-xl"></i></button></a>
                <a target="_blank" rel="noopener" href="https://www.youtube.com/@susanaavella5998"><button class="socialIcon"><i class="fa-brands fa-youtube fa-xl"></i></button></a>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

/*----------------------------------- FOOTER ---------------------------------------*/

.footer{
    display: flex;
    justify-content: space-between;
    padding: 50px 300px 50px 300px;

    p{
        font-size: 18px;
        line-height: 80px;
    }

    #logoFooter{
        height: 70px;
    }
}

.socialIconsContainer{
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 1458px){
    .footer{
        flex-direction: column;
        padding: 20px 0px 20px 0px;
        text-align: center;

        p{
            font-size: 16px;
        }

        #logoNav{
            height: 50px;
        }
    }

    .socialIconsContainer{
        margin: auto;
    }
}

</style>