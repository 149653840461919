<template>
    <Transition>
        <section class="sideMenuContainer" id="sideMenu" v-if="visible">
            <div class="sideMenu">
                <div class="closeSideMenu" v-on:click="toggleMenu"><img src="@/assets/x.png" alt=""></div>
                <li v-on:click="toggleMenu"><router-link to="/" class="menuElement">Inicio</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link to="/articulos" class="menuElement">Artículos</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link to="/entrevistas" class="menuElement">Entrevistas</router-link></li>
                <hr>
                <li v-on:click="toggleMenu"><router-link :to="{ path: '/', hash: '#about' }" class="menuElement">Sobre Mi</router-link></li>
            </div>
        </section>
    </Transition>
    <section class="navBar">    
        <div class="menuNavContainer">
            <router-link to="/"><img id="logoNav" src="@/assets/logoNav_Light.png" alt="logoNav"></router-link>
            <div class="navMenu">
                <ul class="menu">
                    <li><router-link to="/" class="menuElement">Inicio</router-link></li>
                    <li><router-link to="/articulos" class="menuElement">Artículos</router-link></li>
                    <li><router-link to="/entrevistas" class="menuElement">Entrevistas</router-link></li>
                    <li><router-link :to="{ path: '/', hash: '#about' }" class="menuElement">Sobre Mi</router-link></li>
                </ul>
            </div>
            <div class="menuIcon" v-on:click="toggleMenu"></div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue';

const visible = ref(false);

function toggleMenu() {
  visible.value = !visible.value;
}
</script>

<style lang="scss" scoped>

/*-----------------------------NAV BAR----------------------------------*/

.navBar{
    z-index: 100;
    width: 100vw;
    height: 85px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.28);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 0px;
    backdrop-filter: blur(3px);
    position: fixed;
    transition: all 400ms ease-in-out;
}

.menuNavContainer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-width: 80%;
}

#logoNav{
    height: 70px;
}

.navMenu{
    display: flex;
    width: 500px;
}

.menu{
    display: inline-flex;
    flex-direction: row;
    list-style: none;
    width: 500px;
    justify-content: space-around;

    .menuElement{
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        text-decoration: none !important;
        color: #FFF;
        opacity: 0.8;
        transition: 0.5s ease;
        line-height: 20px;
        display: inline-block;
    
        &:hover{
            opacity: 1;
            transform: scale(1.10);
        }
    }
}

.menuIcon{
    display: none;
}

.sideMenuContainer{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 101;
    display: inline-flex;
    backdrop-filter: blur(10px);

    .closeSideMenu img{
        width: 25px;
        position: absolute;
        top: 30px;
        right: 30px;
        filter: invert(1);
    }
}


.sideMenu{
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    background-color: rgba(0,0,0,0.4);
    
    hr{
        width: 250px;
        opacity: 50%;
    }

    .menuElement{
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        text-decoration: none !important;
        color: #FFF;
        opacity: 0.8;
        transition: 0.5s ease;
        line-height: 20px;
        display: inline-block;
        margin: 35px 0px 35px 0px;
    
        &:hover{
            opacity: 1;
            transform: scale(1.10);
        }
    }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media (max-width: 1000px){
    .navMenu{
        display: none;
    }

    .menuNavContainer {
        max-width: 96%;
    }

    .menuIcon{
        display: flex;
        background-image: url("@/assets/menu.png");
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(1);
    }
    
}

</style>