<template>
  <section class="atencion-virtual">
    <div class="atencion-virtual__background">
      <h2 class="atencion-virtual__title">Atención Virtual para Argentinos en el Extranjero</h2>
      <a target="_blank" rel="noopener" href="https://wa.me/541159584020?text=Hola%20buenos%20d%C3%ADas%20Susana%21%0A%0AMe%20gustaria%20agendar%20una%20consulta%20psicol%C3%B3gica"><button class="button_style_1">Agendar Consulta</button></a>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

/* --------------- ATENCION VIRTUAL ----------------------*/
.atencion-virtual{

  .atencion-virtual__background{
      background-image: url("@/assets/flags/flags.webp");
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 96px 0px 96px 0px;
      

      &:after{
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.8);
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 19;
      }
      
      .atencion-virtual__title{
          text-align: center;
          font-weight: 400;
          font-size: 30px;
          padding: 0px 0px 48px 0px;
          color: #000;
          z-index: 200;
          position: relative;
      }

      a{
          z-index: 200;
      }
  }

  .button_style_1{
      z-index: 200;
      position: relative;
  }
}

</style>