<template>
    <SideMenu/>
    <router-view/>
</template>

<script>
import SideMenu from '@/components/SideMenu/SideMenu.vue';
import NavBar from '@/components/NavBar/NavBar.vue';

export default {
  
  components: {
    SideMenu,
  }
}
</script>

<style>

@import "./styles/styles.css";

</style>
