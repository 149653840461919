<template>
    <header>
        <section class="banner" id="bannerTal-Vez">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Tal Vez</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 29/07/2024</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>Tal Vez</h2>
                <p style="margin-bottom: 10px;">
                    Tal vez te hayas despertado en una cama calentita, tal vez tu casa estaba calefaccionada.
                    Tal vez te estiraste en la cama y sentiste tu cuerpo entero.  Tal vez desayunaste rico,
                    tal vez alguien te haya preparado un jugo de naranjas y compró medialunas. Tal vez fuiste
                    a la escuela, a trabajar o a entrenar, y lo pasaste bien. Tal vez sentiste que pudiste
                    superarte o te incentivó aprender cosas nuevas. Tal vez las aves se posaron en los cables
                    y estabas ahí para apreciarlas. Tal vez te contactaron más alumnos y ves crecer tu emprendimiento.
                    Tal vez llegaste a Machu Pichu en bici. Tal vez conseguiste un sponsor que te hizo llegar sus
                    productos como muestra de su confianza y recompensa a tu esfuerzo diario. Tal vez no tengas
                    una compañera estable pero siempre aparece una ocasional que te invitar a jugar y compartir.
                    Tal vez hayas sentido congoja al entrenar y hubo unos brazos que te alojaron para llorar y desahogarte.
                    Tal vez tu copiloto no pudo acompañarte unas fechas, pero aparecieron otros que se ofrecieron a
                    asistirte y comprobaste que tenés regularidad y consistencia. Tal vez siempre encontrás compañía
                    para las largas horas de entreno en las sierras a pesar del frío.
                    <br> <br>
                </p>
                <p>
                    Tal vez te volvieron las ganas de entrenar y ahora todo cuesta menos.
                    Tal vez tenés ganas de bailar, moverte y festejar. Tal vez te empezaste a
                    hablar mejor y los saltos volvieron a salir. Tal vez sentís que todo se ordena para mejor.
                    Tal vez de estar en el banco pasaste a ser titular. Tal vez estás sumando más minutos.
                    Tal vez tu equipo haya salido Campeón. Tal vez hayas clasificado al Panamericano o tal vez al Mundial. 
                    Tal vez clasificaste a ambos. Tal vez tu destino te encuentre en París.
                    Tal vez tengas muchos motivos para soñar y convertir tus sueños en realidad. 
                    <br> <br>
                </p>
                <p>
                    Tal vez algo de todo esto hayas vivido esta semana y aún no encontraste el
                    tiempo para reflexionar y agradecer que esto te sucediera.
                    <br> <br>
                </p>
                <p>
                    Agradecer siempre
                    <br> <br>
                </p>
                <p>
                    *Agradecida de trabajar con Uds.
                </p>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';


export default {
    components: {
        Footer,
    } 
}
</script>

<style>

</style>