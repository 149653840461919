<template>
    <header>
        <section class="banner" id="bannerElHabito">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">El Hábito Te Hace Monje</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 16/12/2020</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>El Hábito Te Hace Monje</h2>
                <div class="citaContainer">
                    <p class="citaBlog">
                        “Al día siguiente el Principito regresó.
                        Hubiese sido mejor regresar a la misma hora
                        – dijo el zorro. –
                        Si vienes, por ejemplo, a las cuatro de la tarde,
                        ya desde las tres comenzaré a estar feliz.
                        Cuanto más avance la hora, más feliz me sentiré.
                        Al llegar las cuatro, me agitaré y me inquietaré;
                        descubriré el precio de la felicidad!
                        Pero si vienes en cualquier momento,
                        nunca sabré a qué hora preparar mi corazón…
                        Es bueno que haya ritos.”
                        <br>
                        El Principito –Antoine de Saint Exupéry
                        <br><br>
                    </p>
                </div>
                <p>
                    “Habíamos previsto que saldríamos para la cumbre hacia las doce de
                    la noche, con la idea de tener tiempo más que suficiente para subir
                    a la cima y bajar luego sin problemas. Pero antes de acostarme empecé
                    a preparar el material para el día siguiente, algo que resulta muy
                    conveniente porque, como he dicho, toda operación lleva mucho tiempo y
                    requiere mucha concentración a aquella altura, y es mejor hacer las
                    cosas con la mayor antelación posible. Además ya por aquel entonces
                    había comenzado a desarrollar mis pequeñas “manías”, que luego he
                    sofisticado todavía más y que me gusta respetar, porque pienso que
                    me dan serenidad y me infunden la sensación de que lo tengo todo bajo
                    control. Desde aquella ocasión en el Everest tengo mis rituales, y
                    cada vez que me preparo para una ascensión a la cumbre comienzo por
                    organizar la mochila que me voy a llevar. Siempre llevo una camiseta
                    blanca que me regaló Silvio y que ha ascendido a los catorce ochomiles
                    conmigo, y que no me pongo en toda la ascensión, únicamente el día del 
                    ataque a la cima. También tengo una serie de estampitas que mi abuela me
                    ha ido dando cada vez que he ascendido a una montaña de 8.000 metros.
                    Mi abuela siempre dijo que no quería morirse hasta que yo terminara los
                    catorce, y, en efecto, me ha visto hacerlo. Las estampitas han estado
                    hasta ese día en la mochila, pero entonces, cuando vamos a atacar la cumbre,
                    las pongo dentro de una bolsa de plástico cerrada herméticamente y las llevo
                    conmigo en el «buzo», el equipamiento que llevamos para escalar. También me
                    preparo los calcetines, siempre limpios, siempre nuevos, para estrenar.
                    Los agarro el día anterior y cuando me acuesto los aprieto contra el pecho
                    para que estén calentitos, y en el último momento me quito los viejos y me
                    pongo los nuevos. Éste es mi ritual antes de la cumbre. Y, en efecto, puedo
                    decir que lo he hecho cada vez que he ascendido una montaña de 8.000 metros,
                    porque ya en aquella ocasión lo hice de cabo a rabo (sólo que el “sobre” de 
                    las estampitas ha ido creciendo con los años y con las cumbres conquistadas).
                    Y no es que sea supersticiosa. Muchos deportistas tienen sus pequeñas manías:
                    entrar en el campo con el pie izquierdo, santiguarse tres veces antes de
                    empezar un partido, llevar alguna prenda o pulsera… Todo me parece
                    respetable, son pequeños “amuletos” a los que te aferras. En mi caso,
                    pienso que toda la serie de pasos que doy cada vez contribuyen a
                    tranquilizarme y me ayudan a concentrarme.” <strong>Edurne Pasaban, “Catorce veces ocho mil”</strong>
                    <br> <br>
                </p>

                <p>
                    La cita de El Principito y el párrafo precedente nos pueden servir para
                    reflexionar acerca de la importancia, a nivel mental, que tiene para un
                    deportista, el sostener rutinas de preparación. Un aspecto de la fortaleza 
                    mental en el deporte es poder mantener el rendimiento bajo presión y en las
                    condiciones más adversas. A la vez se espera que el mismo sea de igual nivel o
                    superior al del entrenamiento. Las rutinas de preparación son un instrumento
                    útil a ese fin ya que promueven que el deportista esté plenamente centrado en
                    la tarea que lo ocupa, en los estímulos significativos del presente, afianzado
                    en su preparación, en sus fortalezas y logros (autoconfianza) y con la gestión
                    óptima de sus emociones. Las rutinas son prácticas que el deportista hace
                    sistemáticamente en uno o algunos espacios (en el entrenamiento, en su preparación
                    para el mismo, en relación a la vestimenta, alimentación y descanso) y que reproduce
                    en la previa a las competencias para tener la sensación de control en un contexto
                    donde prima la incertidumbre. Es su manera determinada de hacer las cosas.
                    (Ejemplos: preparar el bolso de manera singular, comer alimentos específicos, ver
                    un video en particular, repasar las indicaciones del entrenador, estar con personas
                    especiales, hacer determinados ejercicios de respiración o visualización, etc.)
                    El objetivo principal de la rutina es generar familiaridad y confianza en el atleta,
                    que recurra y encuentre las sensaciones específicas que lo hacen dar lo mejor de sí.
                    Las rutinas ayudan a la sensación de control y calma ante un escenario incierto en
                    relación al desenlace de la competencia o evento.
                    <br> <br>
                </p>

                <p>
                    Características de las rutinas: <br>
                    <ul>
                    <li class="listaBlog"> Son siempre accesibles para el deportista, ya que siempre están a su disposición, donde quiera que vaya.</li>
                    <li class="listaBlog"> Son un hábito adquirido, tienen una impronta singular. </li>
                    <li class="listaBlog"> Regulan la activación para que quien las realiza encuentre el nivel energético ideal  para optimizar su accionar. </li>
                    <li class="listaBlog"> Ayudan al deportista a enfocarse en lo significativo. </li>
                    <li class="listaBlog"> Dan sensación de control y tranquilidad. </li>
                    <li class="listaBlog"> Son independientes del resultado que se obtenga (no se cambian aunque no se logre el objetivo buscado)</li>
                    </ul>
                    <br> <br>
                </p>

                <p>
                    Al desarrollar su rutina, el deportista paulatinamente va cerrando ventanas,
                    evita distractores y se focaliza en lo relevante de la tarea por venir. 
                    a serie de acciones que componen una rutina hace que el atleta esté ocupado
                    sin dar tiempo extra al pensamiento, que la mente no divague en el pasado
                    que podría repetirse o vaticinando un futuro incierto. En la previa a las
                    competencias, la ansiedad competitiva puede ocasionar en el deportista una
                    distorsión en su percepción del paso del tiempo. Son frecuentes las sensaciones
                    de que no hay tiempo para nada, que los sucesos pasan vertiginosamente o
                    todo lo contrario, que los minutos son eternos y el tiempo se dilata al
                    modo de los relojes de Dalí. Una de las tareas del psicólogo deportivo es
                    colaborar con el deportista para que desmenuce e identifique las acciones
                    que le han servido para estar en armonía y llevar su actuación al límite
                    de sus posibilidades.
                    <br> <br>
                </p>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';


export default {
    components: {
        Footer,
    } 
}
</script>

<style>

</style>