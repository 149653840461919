<template>
    <header>
          <section class="banner" id="bannerApuntesTria">
            <div class="bannerOverlay"></div>
            <div class="infoBanner">
                <h1 class="bannerHeading">Apuntes Sobre Tria</h1>
                <h2 class="subHeadingBlog">Lic. Susana Avella - 12/02/2022</h2>
            </div>
        </section>
    </header>
    <main>
        <div style="background-color: #F3F3F3;" class="elementContainer">
            <div class="blogBodyContainer">
                <h2>Apuntes Sobre Tria</h2>
                <p>
                    El triatlón es un deporte que consta de tres disciplinas
                    que se realizan por etapas: natación, ciclismo y pedestrismo.
                    Siempre se mantiene ese orden de sucesión y no hay interrupción
                    entre una etapa y la siguiente, salvo por el tiempo que tarda el
                    deportista en prepararse para la disciplina posterior, lapso al
                    que se denomina “transición”. <br> <br>
                </p>

                <p>
                    Para muchos triatletas la natación es el último deporte que
                    aprendieron y lo hicieron “de grandes” y eso puede conllevar
                    alguna dificultad. No sólo nadar sino hacerlo en aguas abiertas,
                    con un traje de neoprene que cambia las sensaciones del cuerpo.
                    En la pileta, donde generalmente se entrena, hay bordes y se ve
                    el fondo de la misma, que más cerca o más lejos, está ahí, accesible.
                    En los lagos, lagunas y mares, donde se desarrollan los trías,
                    no ocurre lo mismo. Difícilmente se ve a través del agua, más que
                    a unos pocos metros o centímetros o en los lagos de aguas frías y
                    cristalinas se ve con nitidez y el fondo no se percibe más que por
                    su profunda oscuridad. <br> <br>
                </p>

                <p>
                    Esta sensación de no ver y el no haber un límite, de no estar contenido,
                    como ocurre en la piscina,  puede representar un gasto adaptativo importante,
                    si no se lo preparó de antemano. Siempre es aconsejable entrenar en
                    lugares lo más parecidos posible al escenario donde luego se desarrollarán
                    las competencias. Hay deportistas que sienten vértigo al ver cómo aumenta
                    abruptamente la profundidad del lago, en una zona muy cercana a la costa.
                    Es aconsejable buscar referencias, para sostener la visión en esos puntos
                    que no sólo orientarán el recorrido a seguir, sino que oficiarán de sostén
                    psicológico. (Ej. Boyas, embarcaciones, rocas, árboles, etc.) <br> <br>
                </p>

                <p>
                    Otro punto a considerar es que, cuando se entrena natación por lo general
                    se lo hace ordenadamente en un andarivel. Nada más lejano al inicio de
                    un tría, que se caracteriza por el desorden, los manotazos y las patadas
                    hasta que los participantes se acomodan y van encontrando su lugar.
                    Es importante trabajar la entrada al agua, sobre todo para quienes no
                    tienen un buen vínculo con ella, ya que las malas sensaciones pueden
                    arrastrarse a lo largo de toda la competencia, en las dos siguientes etapas.
                    Para ello, es aconsejable entrenar en espejos de agua lo más parecidos
                    al escenario de la competencia; entrenar nadar con los ojos cerrados en
                    la pileta y ser consciente de las sensaciones y pensamientos que se tienen; 
                    entrenar con mucho ruido para trabajar la concentración;  realizar
                    ejercicios de relajación en el agua, como hacer “la plancha”
                    (inhalar y exhalar tomando registro de cómo el cuerpo se eleva en el
                    agua y se sumerge según se inhale o exhale); tener una buena entrada
                    en calor y alcanzar la activación óptima para el inicio de la carrera, etc. <br> <br>
                </p>

                <p>
                    Es conveniente tener una meta para cada disciplina, para estar más
                    concentrados y presentes durante el transcurso del evento. 
                    En la o las disciplinas donde el deportista tenga fortalezas
                    puede tener una meta de resultado, que busque un resultado parcial.
                    En otra u otras la meta puede ser mejorar la técnica, trabajar en la
                    regulación de los pensamientos, mejorar un desempeño anterior, etc. <br><br>
                </p>

                <p>
                    La habilidad para poner el foco a corto plazo es fundamental.
                    Para concentrarse en eso que se está haciendo ahora, sin importar
                    cómo fue el tránsito por el agua o por la bici y no sacar de eso un
                    vaticinio y querer compensar con lo que sigue. Cada etapa es una
                    carrera en sí misma y el deportista tendrá que poder enfocarse en
                    cuáles son los recursos particulares que tiene para llevar adelante
                    cada etapa. Así como se pone el casco y las zapatillas para hacer
                    la etapa de ciclismo tendrá que poder cambiar el chip de nadador,
                    a ciclista y por último a corredor, sabiendo cuáles son sus fortalezas,
                    para ponerlas en práctica y estar advertido de sus debilidades,
                    en cada disciplina y tener tips para poder contrarrestarlas.
                    La música puede ser un recurso privilegiado. Como cada disciplina
                    exige un grado de activación distinto, pueden seleccionarse temas
                    musicales para cada una y tararearlas en los momentos que sea necesario
                    llevar la activación a un determinado nivel o repetir estribillos
                    como si fueran “mantras”. <br><br>
                </p>

                <p>
                    Respecto de las transiciones, hay que prepararlas y entrenarlas
                    tanto como las tres disciplinas. Hacer un plan, memorizarlo,
                    idear una rutina y practicarla. Tomar referencias para cuando se
                    llega de nadar para orientarse rápidamente en el parque cerrado.
                    Hacer ese recorrido previamente a la carrera. Ejecutar y visualizar
                    cómo desenganchar la bicicleta y tomar el casco y calzarse las
                    zapatillas, así como también las maniobras de la T2, que será a
                    la inversa y cómo se tomarán los implementos necesarios para la
                    etapa de pedestrismo. Es crucial el entrenar el vestirse y
                    desvestirse en poco tiempo y con poco lugar. Cuanto más se
                    trabaje en lo que se pueda controlar y los escenarios sean lo
                    más familiares posible, eso optimizará el gasto energético físico
                    y mental y dejará una reserva para enfrentar los imponderables que
                    puedan presentarse a lo largo de la competencia. Para eso sirven los
                    entrenamientos de simulación. Seguramente el triatleta vaya a nadar
                    y vuelva a su casa, o se prepare para andar en bici, vuelva y vaya a
                    trabajar o a estudiar. Es deseable que entrene salir de un estímulo y
                    continuar con otro, al modo de cómo lo hará en competencia.
                    Participar en desafíos diseñados por teams de trías para entrar
                    en clima de competencia son entrenamientos de simulación indispensables
                    para calibrar qué cosas hay que ajustar de las transiciones,
                    y en cada etapa del tría.
                </p>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';


export default {
    components: {
        Footer,
    } 
}
</script>

<style>

</style>